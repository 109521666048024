import React from "react";
import AdminWrapper from "../styling/Admin.style";
import { Link } from "react-router-dom";

const Admin = () => {
  return (
    <AdminWrapper>
      <main>
        <div className="admin">
          <Link to="/admin/applications">
            <button className="applications">View Applications</button>
          </Link>
          <Link to="/admin/contacts">
            <button className="contacts">View Contacts</button>
          </Link>
        </div>
      </main>
    </AdminWrapper>
  );
};

export default Admin;
