import React, { useEffect, useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";

import { db } from "../firebaseConfig/config";
import AdminWrapper from "../styling/Admin.style";
import PDFViewer from "../components/PDFViewer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminApplications = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [PdfUrl, setPdfUrl] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (pdf) => {
    setOpen(true);
    setPdfUrl(pdf);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getApplications = async () => {
    let arr = [];
    const q = query(collection(db, "application-form"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
      setData(arr);
    });
  };

  const columns = [
    { id: "#", label: "#" },
    { id: "Name", label: "Name" },
    { id: "Email", label: "Email" },
    { id: "Phone", label: "Phone" },
    { id: "Country", label: "Country" },
  ];

  const rows = data?.map((item, index) => {
    return {
      "#": index + 1,
      Name: item.fullName,
      Country: item.country,
      Email: item.email,
      Phone: item.phone,

      cv: item.cv,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <AdminWrapper>
      <div className="main">
        <div className="container">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer style={{ minHeight: 620 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="relative z-0">
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        <p className="font-bold">{column.label}</p>
                      </TableCell>
                    ))}
                    <TableCell>
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        Action
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return <TableCell key={index}>{value}</TableCell>;
                          })}
                          <TableCell
                            className="cursor-pointer"
                            onClick={() => handleClickOpen(row.cv)}
                            style={{
                              fontWeight: 700,
                              fontSize: 22,
                              textAlign: "center",
                            }}
                          >
                            <span>
                              <AiOutlineEye />
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <AiOutlineClose />
            </IconButton>
          </Toolbar>
        </AppBar>
        <PDFViewer url={PdfUrl} />
      </Dialog>
    </AdminWrapper>
  );
};

export default AdminApplications;
