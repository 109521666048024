import React from "react";
import { RiMailSendFill } from "react-icons/ri";
import { TbBellRinging } from "react-icons/tb";
import { GoMegaphone } from "react-icons/go";
import styled from "styled-components";
import { ExtraButton } from "./style-components/Button";
// "https://i.ibb.co/Cpdfk8f/pexels-mikhail-nilov-7470820.jpg"
// "https://i.ibb.co/j6LYg41/extrabg.jpg"
// <a href="https://ibb.co/5krmMPt"><img src="https://i.ibb.co/w7SV0f3/extraBg2.jpg" alt="extraBg2" border="0"></a>
//<a href="https://ibb.co/WkhfPJv"><img src="https://i.ibb.co/v3FkQn4/extrabg.jpg" alt="extrabg" border="0"></a>
const ExtraSec = styled.section`
  width: 100vw;
  height: auto;
  background: url("https://i.ibb.co/w7SV0f3/extraBg2.jpg");
  background-size: contain;
  background-attachment: fixed;
`;

const ExtraSectionContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
    padding: 150px 10px;
  }
`;

const ExtraSectionCard = styled.div`
  width: 60%;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  @media screen and (min-width: 800px) {
    width: 20%;
    min-width: 300px;
    margin-right: 80px;
  }
`;

const ExtraSectionFigure = styled.figure`
  font-size: 32px;
  background-color: aliceblue;
  width: 100px;
  height: 100px;
  margin: 80px 10px;
  margin-top: 0;
  position: relative;
  left: 45%;
  transform: translate(-50%, 50%);

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
`;

const ExtraCardName = styled.h3`
  font: 16px 600;
`;
const ExtraCardDescription = styled.p`
  font: 14px 400;
  letter-spacing: 1px;
  line-height: 27px;
`;

const ExtraSection = () => {
  return (
    <ExtraSec>
      <ExtraSectionContainer>
        <ExtraSectionCard>
          <ExtraSectionFigure>
            <RiMailSendFill style={{ color: "#001b56" }} />
          </ExtraSectionFigure>
          <ExtraCardName>Send us your CV</ExtraCardName>
          <ExtraCardDescription>
            If you are interested in relocating to the UK as a healthcare
            professional, MSI can help.
          </ExtraCardDescription>

          <ExtraButton marginTop="20px">Send CV</ExtraButton>
        </ExtraSectionCard>

        <ExtraSectionCard>
          <ExtraSectionFigure>
            <TbBellRinging style={{ color: "#001b56" }} />
          </ExtraSectionFigure>
          <ExtraCardName>Job alerts</ExtraCardName>
          <ExtraCardDescription>
            Set up an automatic job alert and you'll be notified every time we
            add a job that's suitable for you.
          </ExtraCardDescription>

          <ExtraButton marginTop="20px">Set up alerts</ExtraButton>
        </ExtraSectionCard>

        <ExtraSectionCard>
          <ExtraSectionFigure>
            <GoMegaphone style={{ color: "#001b56" }} />
          </ExtraSectionFigure>
          <ExtraCardName>Referrals</ExtraCardName>
          <ExtraCardDescription>
            Do you have a friend or colleague looking for a new role? Refer them
            to us!
          </ExtraCardDescription>

          <ExtraButton marginTop="20px">Refer a friend</ExtraButton>
        </ExtraSectionCard>
      </ExtraSectionContainer>
    </ExtraSec>
  );
};

export default ExtraSection;
