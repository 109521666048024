// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCafnyUWqyoo78iHJJn5KmTsqkDU3OTZ9I",
  authDomain: "chatapp-88a49.firebaseapp.com",
  projectId: "chatapp-88a49",
  storageBucket: "chatapp-88a49.appspot.com",
  messagingSenderId: "365671911547",
  appId: "1:365671911547:web:0695505f1c5380b3b0d2de",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
