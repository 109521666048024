import React, { useEffect, useState } from "react";
import CountrySelect from "../components/CountrySelect";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig/config";

const ContactForm = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (isLoading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isLoading]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    await setDoc(doc(db, "contact-form", data.email + Date.now()), data);
    setTimeout(() => {
      setIsLoading(false);
      reset();
      setMsg("Message sent successfully");
    }, 1500);
  };

  return (
    <div className="sendMessage">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control">
          <label htmlFor="firstName">* First name</label>
          <input
            type="text"
            name="firstName"
            {...register("firstName", { required: true })}
            aria-invalid={errors.firstName ? "true" : "false"}
          />
          {errors.firstName?.type === "required" && (
            <p role="alert">First name is required</p>
          )}
        </div>

        <div className="form-control">
          <label htmlFor="lastName">* Last name</label>
          <input
            type="text"
            name="lastName"
            {...register("lastName", { required: true })}
            aria-invalid={errors.lastName ? "true" : "false"}
          />
          {errors.lastName?.type === "required" && (
            <p role="alert">Last name is required</p>
          )}
        </div>

        <div className="form-control email">
          <label htmlFor="email">* Email address</label>
          <input
            type="email"
            name="email"
            {...register("email", { required: true })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email?.type === "required" && (
            <p role="alert">Email is required</p>
          )}
        </div>

        <div className="form-control">
          <label htmlFor="country">* Country</label>
          <select
            id="country"
            name="country"
            {...register("country", { required: true })}
            aria-invalid={errors.country ? "true" : "false"}
          >
            <CountrySelect />
          </select>
          {errors.country?.type === "required" && (
            <p role="alert">Country is required</p>
          )}
        </div>

        <div className="form-control">
          <label htmlFor="phoneNumber">* Phone number</label>
          <input
            type="text"
            name="phoneNumber"
            {...register("phone", { required: true })}
            aria-invalid={errors.phone ? "true" : "false"}
          />
          {errors.phone?.type === "required" && (
            <p role="alert">Phone number is required</p>
          )}
        </div>

        <div className="form-control message">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            {...register("message", { required: true })}
            aria-invalid={errors.message ? "true" : "false"}
          ></textarea>
          {errors.message?.type === "required" && (
            <p role="alert">Message is required</p>
          )}
        </div>

        <div className="msg">
          <p>{msg}</p>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Submiting" : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
