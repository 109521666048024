import styled from "styled-components";

const AdminWrapper = styled.section`
  main {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .admin {
    margin-top: 12rem;
    margin-bottom: 2rem;
    background: white;
    border-radius: 16px;
    height: 85vh;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  button {
    height: 10rem;
    width: 20rem;
    border: 0;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  .applications {
    background: #ffae3a;
  }
  .contacts {
    background: blue;
  }
  .main {
    padding-top: 12rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background: #909cb0; */
    background: grey;
  }
  article {
    width: 70vw;
    min-height: 10rem;
  }
  embed {
    min-height: 90vh;
  }
  .viewCV {
    font-size: 1rem;
    padding: 0 6px;
    height: 2rem;
    width: auto;
    color: black;
  }
  .contactItem {
  }
  @media (max-width: 767px) {
    .admin {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    button {
      height: 8rem;
      width: 15rem;
    }
  }
`;

export default AdminWrapper;
