import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { auth } from "../firebaseConfig/config";

import AuthWrapper from "../styling/login.style";
import { setUser } from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      dispatch(setUser(auth.currentUser));
      navigate("/admin");

      reset();
    } catch (error) {
      setMsg("Invalid Credentials");
    }
    setIsLoading(false);
  };
  return (
    <AuthWrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-box">
            <input
              type="email"
              name=""
              {...register("email", {
                required: "email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              aria-invalid={errors.email ? "true" : "false"}
              style={errors.email && { borderBottom: "1px solid red" }}
            />
            <label>Email</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              name=""
              {...register("password", {
                required: "password is required",
              })}
              aria-invalid={errors.password ? "true" : "false"}
              style={errors.email && { borderBottom: "1px solid red" }}
            />
            <label>Password</label>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default Login;
