import React from "react";

const PDFViewer = ({ url }) => {
  return (
    <object data={url} type="application/pdf" width="100%" height="97%">
      <p>
        Your browser does not support PDFs. Please download the PDF to view it:{" "}
        <a href={url}>Download PDF</a>.
      </p>
    </object>
  );
};

export default PDFViewer;
