import styled from "styled-components";

export const Button = styled.button`
  background: #ffc13b;
  font-size: 12px;
  color: #001b56;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;

  & a {
    text-decoration: none;
    color: #001b56;
  }

  @media screen and (max-width: 992px) {
    display: ${(props) => props.breakpoint === "large" && "none"};
    background: #001b56;
    color: white;
    border: none;
    box-shadow: 2px 2px 2px 2px rgba(128, 123, 100, 0.164);
    width: 100%;
  }

  &:hover {
    background: #fdb210;
  }
`;

export const HeroButton = styled.button`
  background: #ffc13b;
  font-size: 12px;
  color: #001b56;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1020px) {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
    background: #fdb210;
  }
`;

export const ExtraButton = styled.button`
  background: #ffc13b;
  font-size: 12px;
  color: #001b56;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 800px) {
    width: 70%;
  }

  &:hover {
    background: #fdb210;
  }
`;
