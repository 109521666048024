import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import SessionStorage from "redux-persist/lib/storage/session";
import { combineReducers } from "@reduxjs/toolkit";
import UserReducer from "./features/user/UserSlice";

const persistConfig = {
  key: "root",
  storage: SessionStorage,
};

const rootReducer = combineReducers({
  user: UserReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
