import React, { useState } from "react";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import BNW from "../images/BLACKNWHITE.png";
import FooterWrapper from "../styling/footer.style";
import { Link } from "react-router-dom";

function Footer() {
  const [showHelp, setShowHelp] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showSocials, setShowSocials] = useState(false);
  return (
    <FooterWrapper>
      <section className="footer_mini">
        <div className="signup_sec">
          <p className="signup_info">
            signup to hear about our latest offers and promotions
          </p>
          <div className="signup">
            <input type="text" placeholder="Please Enter Your Email Address" />
            <button className="signup_btn">sign up</button>
          </div>
          {/* <p className="terms">terms and conditions</p> */}
        </div>
        <div className="extra_info_dropdown">
          <div className="dropdown_sec">
            <div
              className="dropdown"
              onClick={() => setShowHelp(showHelp === false ? true : false)}
            >
              <h3>need help?</h3>
              {showHelp ? (
                <RiArrowDropUpLine
                  style={{
                    color: "white",
                    fontSize: "25px",
                  }}
                />
              ) : (
                <RiArrowDropDownLine
                  style={{
                    color: "white",
                    fontSize: "25px",
                  }}
                />
              )}
            </div>
            {showHelp && (
              <ul className="dropdown_menu">
                <li>
                  <Link to="/contact-us">contact us</Link>
                </li>
                {/* <li>How we Work</li>
                <li>Schedule Meeting</li> */}
              </ul>
            )}
          </div>

          <div className="dropdown_sec">
            <div
              className="dropdown"
              onClick={() => setShowLink(showLink === false ? true : false)}
            >
              <h3>useful links</h3>
              {showLink ? (
                <RiArrowDropUpLine
                  style={{ color: "white", fontSize: "25px" }}
                />
              ) : (
                <RiArrowDropDownLine
                  style={{ color: "white", fontSize: "25px" }}
                />
              )}
            </div>
            {showLink && (
              <ul className="dropdown_menu">
                {/* <li>account logins</li> */}
                {/* <li>locate us</li> */}
                <li>
                  <Link to="/about">about us</Link>
                </li>
              </ul>
            )}
          </div>

          <div className="dropdown_sec">
            {/* <div
              className="dropdown"
              onClick={() =>
                setShowSocials(showSocials === false ? true : false)
              }
            >
              <h3>about us</h3>
              {showSocials ? (
                <RiArrowDropUpLine
                  style={{ color: "white", fontSize: "25px" }}
                />
              ) : (
                <RiArrowDropDownLine
                  style={{ color: "white", fontSize: "25px" }}
                />
              )}
            </div> */}
            {showSocials && (
              <ul style={{ listStyle: "none" }} className="dropdown_menu">
                <li>
                  <a href="/">
                    <FaFacebookF className="socials" />
                  </a>
                  <a href="/">
                    <FaInstagram className="socials" />
                  </a>
                  <a href="/">
                    <FaTwitter className="socials" />
                  </a>
                </li>
                {/* <li>medical journal - Golden Consult</li> */}
                <li>
                  © Copyright {new Date().getFullYear()} Golden Consult. All
                  rights reserved.
                </li>
              </ul>
            )}
          </div>
        </div>
      </section>

      {/*********************************  Max **********************************/}
      <section className="footer">
        <div className="signup_sec">
          <div className="footer-logo">
            <img src={BNW} alt="logo" />
          </div>
          <div className="signup-desc">
            <p className="signup_info">
              signup to hear about our latest offers and promotions
            </p>
            <div className="signup">
              <MdOutlineMarkEmailUnread className="email" />
              <input
                type="text"
                placeholder="Please Enter Your Email Address"
              />
              <button className="signup_btn">sign up</button>
            </div>
            {/* <p className="terms">terms and conditions</p> */}
          </div>
          {/* <div className="footer-logo">
            <img src={BNW} alt="logo" />
          </div> */}
        </div>

        <div className="extra_info_sec">
          <div className="extra_info">
            <ul className="extra_info_list">
              <h3>need help?</h3>
              <li>
                <Link to="/contact-us">contact us</Link>
              </li>
              {/* <li>How we Work</li>
                <li>Schedule Meeting</li> */}
            </ul>
            <ul className="extra_info_list">
              <h3>useful links</h3>
              <li>
                <Link to="/about">about us</Link>
              </li>
            </ul>
            <ul className="extra_info_list">
              {/* <h3>about us</h3> */}
              <li>
                <a href="/">
                  <FaFacebookF className="socials" />
                </a>
                <a href="/">
                  <FaInstagram className="socials" />
                </a>
                <a href="/">
                  <FaTwitter className="socials" />
                </a>
              </li>
              {/* <li>medical journal - Golden Consult</li> */}
              <li>
                © Copyright {new Date().getFullYear()} Golden Consult. All
                rights reserved.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </FooterWrapper>
  );
}

export default Footer;
