import styled from "styled-components";

const FormsWrapper = styled.section`
  .form-details {
    margin-top: 100px;
    width: 100vw;
    padding: 50px;
    min-height: 41rem;
    display: flex;
    justify-content: center;
  }

  .main-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 800px;
    border: 1px solid rgba(179, 179, 179, 0.891);
    border-radius: 20px;
  }

  .main-form form {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .main-form form input,
  .main-form form select {
    color: black;
    background-color: transparent;
    outline: none;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);
  }
  input[type="file" i] {
    color: black;
  }

  .main-form form label {
    margin-bottom: 10px;
    color: #001543;
  }

  .asterisk {
    color: #ffc13b;
  }

  .form-figure {
    flex: 1;
    min-height: 500px;
    position: relative;
    border-radius: 20px 20px 0 0;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0 0;
  }

  .form-figure img {
    width: 100%;
    min-height: 250px;
    border-radius: 20px 20px 0 0;
  }
  button {
    border: none;
    width: 10rem;
    height: 2.5rem;
    border-radius: 4px;
    font-weight: 700;
    background-color: skyblue;
    color: white;
    cursor: pointer;
  }
  .msg {
    text-align: center;
    height: 2rem;
    padding: 1rem;
  }

  @media screen and (min-width: 992px) {
    .main-form {
      flex-direction: row;
    }
    .form-details {
      height: 60vh;
    }

    .form-figure {
      height: 100%;
    }

    .form-figure img {
      height: 100%;
    }

    .form-figure,
    .overlay,
    .form-figure img {
      border-radius: 20px 0 0 20px;
    }
  }
  @media (max-width: 992px) {
    .form-figure {
      display: none;
    }
    .form-details {
      padding: 10px;
    }
  }
`;

export default FormsWrapper;
