import styled from "styled-components";

export const SocialButton = styled.span`
  font-size: 16px;
  color: #fff;
  background-color: #1ea8da;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  /* &.scrolled {
    width: 35px;
    height: 35px;
    font-size: 14px;
  } */

  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);

  @media (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
`;

const contact = styled.section`
  .container {
    width: 100%;
  }
  .contact {
    margin-top: 5rem;
    display: flex;
    padding: 10px;
    gap: 2rem;
  }
  .contact iframe {
    width: 65%;
    height: auto;
  }

  .contact-info {
    width: 35%;
  }
  .getInTouch,
  .followUs,
  .contact-form {
    background-image: url("https://www.msiinternational.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMklpQlE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--b46b9b94938dd889d43dac1f25911da189906959/background-blue-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 10px;
  }

  .getInTouch {
    margin-bottom: 2rem;
    height: auto;
    min-height: 300px;
  }

  .followUs {
    background-color: #f2f2f2;
    margin-bottom: 2rem;
    min-height: 150px;
  }

  .contact-info p {
    margin-top: 0;
  }
  .getInTouch li a {
    color: white;
  }
  .getInTouch li {
    position: relative;
    list-style: none;
    margin-bottom: 1rem;
    color: #fff;
  }
  .getInTouch li svg {
    position: absolute;
    left: -30px;
    top: 4px;
    color: #ffae3b;
  }
  .heading {
    /* margin-top: 2rem; */
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2.5rem;
    /* color: #fff; */
  }
  .social-icons {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    justify-content: center;
  }
  .icon svg {
    background-image: url("https://www.msiinternational.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBelFpQlE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--3d4e36ac48e7d3da22b1b7c564ae930e14d378f5/hexagon-blue-light.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px;
    width: 4rem;
    height: 4rem;
  }
  .sendMessage {
    margin: 5rem 0;
    /* background-color: #f2f2f2; */
    min-height: 600px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  /* .sendMessage h5 {
    color: black;
  } */
  .msg {
    color: white;
    grid-column: span 2;
    text-align: center;
  }
  label {
    display: block;
  }
  input,
  select,
  textarea {
    color: black;
    border-radius: 0;
    width: 100%;
    height: 2rem;
    margin-top: 5px;
    border: 1px solid #ccc;
  }
  .contact-form {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 50%;
  }
  .form-control {
    width: 100%;
  }
  .email,
  .message {
    grid-column: 1 / span 2;
  }
  textarea {
    height: 4rem;
  }
  button {
    width: 7rem;
    height: 2rem;
    border: 0;
    color: white;
    background-color: #1ea8da;
    cursor: pointer;
  }
  @media (max-width: 1500px) {
    .contact {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .contact iframe {
      width: 90%;
    }
    .contact-info {
      width: 90%;
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 767px) {
    .contact-form {
      padding: 50px 10px;
    }
    .contact-info {
      width: 90%;
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 1350px) {
    .sendMessage {
      padding: 10px;
    }
    .contact-form {
      width: 100%;
    }

    @media (max-width: 402px) {
      .contact {
        padding: 0;
      }

      .map {
        width: 90%;
      }
      .getInTouch,
      .followUs {
        width: 88%;
      }
    }
  }
`;

export default contact;
