import styled from "styled-components";

const FooterWrapper = styled.footer`
  /*
=============== 
Footer
===============
*/
  .footer {
    display: none;
  }

  .signup_sec {
    background: #909cb0;
    padding: 50px;

    display: grid;
    /* justify-content: center; */
  }

  .signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .signup input {
    width: 100%;
    height: 35px;
    padding: 5px;
    margin: 30px 0 10px 0;
  }

  .signup_btn {
    padding: 8px 20px;
    text-transform: capitalize;
    color: black;
    border: 0;
    cursor: pointer;
  }

  .terms {
    margin-top: 10px;
    color: white;
    font-weight: 200;
    font-size: 8px;
    text-align: center;
    text-transform: capitalize;
  }

  @media screen and (min-width: 451px) {
    .signup_btn {
      margin: 0 5px;
    }
  }

  .signup_info {
    text-align: center;
    color: white;
    text-transform: capitalize;
    font-size: 12px;
  }

  .extra_info_dropdown {
    width: 100vw;
    height: auto;
    background: #909cb0;
    transition: var(--transition);
  }

  .dropdown_sec {
    border-top: 0.2px solid rgba(128, 128, 128, 0.493);
    border-bottom: 0.2px solid rgba(128, 128, 128, 0.493);
    padding: 10px 0;
  }

  .dropdown {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 0 20px;
  }

  .dropdown h3 {
    font-size: 14px;
    color: white;
    font-weight: 600;
  }

  .dropdown_menu {
    padding: 0 20px;
  }

  .dropdown_menu li {
    font-size: 12px;
    color: white;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .socials {
    border: 1px solid #ffc13b;
    width: 30px;
    height: 25px;
    padding: 5px;
    border-radius: 15px/25px;
    color: #fce44d;
    margin-right: 10px;
    margin-top: 10px;
  }
  li a {
    color: white;
    text-decoration: none;
  }

  /************************************* min-width: 900px ******************************/

  @media screen and (min-width: 901px) {
    .footer_mini {
      display: none;
    }

    .footer {
      display: block;

      width: 100vw;
      height: auto;
    }

    .signup_sec {
      background: #909cb0;
      padding: 50px 0;
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
    }

    .footer-logo {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-logo img {
      width: 220px;
      height: 150px;
    }

    .signup-desc {
      flex: 2;
    }

    .email {
      color: white;
      font-size: 85px;
      position: relative;
      top: 35px;
    }

    .signup {
      display: block;
    }

    .signup input {
      width: 250px;
      height: 35px;
      padding: 5px;
      margin: 0 10px;
    }

    .signup_btn {
      padding: 8px 20px;
      text-transform: capitalize;
    }

    .terms {
      margin-top: 10px;
      color: white;
      font-weight: 200;
      font-size: 10px;
      text-align: center;
      text-transform: capitalize;
    }

    .signup_info {
      text-align: left;
      color: white;
      text-transform: capitalize;
      font-size: 14px;
    }

    .extra_info_sec {
      width: 100vw;
      height: auto;
      background: #909cb0;
      padding: 20px 40px;
      display: flex;
    }

    .extra_info {
      width: 100vw;
      display: flex;
      justify-content: center;
      padding: 15px;
    }

    .extra_info_list {
      text-decoration: none;
      color: white;
      display: flex;
      flex-direction: column;
      margin-right: 35px;
    }

    .extra_info_list h3 {
      font-size: 13px;
      margin-bottom: 25px;
      font-weight: 600;
    }
    .extra_info_list svg {
      cursor: pointer;
    }

    li {
      display: block;
      margin-top: 10px;
      font-size: 12px;
      color: white;
      text-transform: capitalize;
    }

    .reviews {
      padding-left: 40px;
      color: white;
    }

    .reviews p {
      font-size: 13px;
    }

    .reviews span {
      font-size: 45px;
    }

    .socials {
      border: 1px solid #ffc13b;
      width: 30px;
      height: 25px;
      padding: 5px;
      border-radius: 15px/25px;
      color: #fff;
      margin-right: 10px;
    }

    @media screen and (min-width: 1440px) {
      li {
        font-size: 13px;
      }
      li a {
        color: white;
        text-decoration: none;
      }

      .extra_info_sec {
        padding: 20px 350px;
      }

      .extra_info_list {
        margin-right: 50px;
      }

      .extra_info_list h3 {
        font-size: 14px;
      }
    }
  }
`;

export default FooterWrapper;
