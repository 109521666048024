import React, { useState, useEffect } from "react";
import FormsWrapper from "../styling/forms.style";
import bg2 from "../images/pexels-cottonbro-studio-5722156.jpg";
import CountrySelect from "../components/CountrySelect";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig/config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const Forms = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const uploadCV = (e) => {
    // if(e.target.files[0].size>1500000)
    setIsUploading(true);
    const cv = e.target.files[0];
    const storageRef = ref(
      storage,
      `cv/${e.target.files[0].name}${Date.now()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, cv);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const uploadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);
        setMsg("Error while uploading cv");
        setTimeout(() => {
          setIsUploading(false);
        }, 3000);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          setTimeout(() => {
            setIsUploading(false);
          }, 1000);
        });
      }
    );
  };

  useEffect(() => {
    if (isLoading || isUploading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isLoading, isUploading]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    await setDoc(doc(db, "application-form", data.email + Date.now()), {
      ...data,
      cv: fileUrl,
    });
    setTimeout(() => {
      setIsLoading(false);
      reset();
      setMsg("Application submitted successfully");
    }, 1500);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Golden Consult | Join our team";
  }, []);
  return (
    <FormsWrapper>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundPosition: "25% 65%",
        }}
      >
        <div className="hero-overlay"></div>
        <h2 className="hero-text">Join Our Team</h2>
      </div>

      <div className="form-details">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-form">
          <figure className="form-figure">
            <div className="overlay"></div>
            <img
              src="https://i.ibb.co/w7SV0f3/extraBg2.jpg"
              alt="leaves background"
            />
          </figure>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name">
              Full Name <span className="asterisk">*</span>
            </label>

            <input
              style={{
                borderColor: errors.fullName?.type === "required" && "red",
              }}
              type="text"
              id="name"
              name="Full-name"
              {...register("fullName", { required: true })}
              aria-invalid={errors.fullName ? "true" : "false"}
            />
            <label htmlFor="email">
              Email <span className="asterisk">*</span>
            </label>
            <input
              style={{
                borderColor: errors.email?.type === "required" && "red",
              }}
              type="email"
              id="email"
              name="Email-address"
              {...register("email", { required: true })}
              aria-invalid={errors.email ? "true" : "false"}
            />

            <label htmlFor="phone">
              Phone Number <span className="asterisk">*</span>
            </label>
            <input
              style={{
                borderColor: errors.phone?.type === "required" && "red",
              }}
              type="phone"
              id="phone"
              name="phone-num"
              {...register("phone", { required: true })}
              aria-invalid={errors.phone ? "true" : "false"}
            />

            <label htmlFor="country">
              Select Country <span className="asterisk">*</span>
            </label>
            <select
              style={{
                borderColor: errors.country?.type === "required" && "red",
              }}
              id="country"
              name="country"
              {...register("country", { required: true })}
              aria-invalid={errors.country ? "true" : "false"}
            >
              <CountrySelect />
            </select>
            <label htmlFor="cv">
              Upload CV <span className="asterisk">*</span>
            </label>
            <input
              style={{
                borderColor: errors.cv?.type === "required" && "red",
              }}
              type="file"
              id="cv"
              name="cv"
              required
              onChange={uploadCV}
            />

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submiting" : "Submit"}
            </button>

            <div className="msg">
              <p>{msg}</p>
            </div>
          </form>
        </div>
      </div>
    </FormsWrapper>
  );
};

export default Forms;
