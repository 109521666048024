import React, { useEffect } from "react";
import Wrapper from "../styling/about.style";
import bgimage from "../images/pexels-rfstudio-3825529.jpg";
import miniimage from "../images/pexels-evg-kowalievska-1170979.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Golden Consult | About Us";
  }, []);

  return (
    <>
      <Wrapper>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${bgimage})`,
          }}
        >
          <div className="hero-overlay"></div>
          <h2 className="hero-text">About Us</h2>
        </div>
        <div className="container">
          <div className="about-sec">
            <img src={miniimage} alt="about" />
            <div className="main-text">
              {/* <h2 className="heading">About Us</h2> */}
              <p className="heading-text">
                Formed in 2022, Golden Consult is a UK based overseas
                recruitment agency consisting of specialist divisions including
                Nursing Care Solutions and Healthcare Solutions. We cover
                positions in both the public and private sector. <br />
                We're committed to creating the most rewarding experiences for
                our customers, clients, colleagues, and our community. <br />
                Our mission is to place the right candidate in the right job for
                their skills, experience, and requirements, and in the shortest
                time possible. We deliver this by ensuring the candidate is at
                the forefront of everything the team at GCS do.
              </p>
              <br />
              <p className="heading-text">
                We are passionate about helping healthcare providers and
                professionals deliver the best care possible. This is made
                possible by the supportive, collaborative, and diverse
                environment in which we work.
              </p>
            </div>
          </div>
          {/* <div className="archievments">
            <div className="archievment">
              <div className="archievment-header">
                <p>3012</p>
              </div>
              <div className="archievment-body">
                <p>
                  Since 2017, MSI has helped 3,012 healthcare professionals find
                  jobs in the UK
                </p>
              </div>
            </div>

            <div className="archievment">
              <div className="archievment-header">
                <p>40</p>
              </div>
              <div className="archievment-body">
                <p>
                  We are currently working with 40 Healthcare Providers to help
                  fill permanent vacancies, helping them save millions on agency
                  spend
                </p>
              </div>
            </div>

            <div className="archievment">
              <div className="archievment-header">
                <p>£2m</p>
              </div>
              <div className="archievment-body">
                <p>
                  Healthcare providers with an average of 100 vacancies save
                  £1.3m in the first year alone and £2m in the second year.
                </p>
              </div>
            </div>

            <div className="archievment">
              <div className="archievment-header">
                <p>99%</p>
              </div>
              <div className="archievment-body">
                <p>99% of our nurses pass their OSCE</p>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="bannerSec">
            <div>
              <h2 className="banner-text">
                <strong>Making a difference </strong>for our clients &
                candidates
              </h2>
              <h2>
                Our mission is to help healthcare providers access the global
                talent pool, fill key staffing gaps and reduce agency costs.
              </h2>
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="testimonials">
                <h2>"​Working with the team at MSI is a pleasure"</h2>
                <p>
                  ​Working with Georgina and the team at MSI is a pleasure.
                  There is always excellent communication and I know that the
                  candidates are being well looked after prior to their arrival
                  at the Trust.​I have full confidence in MSI and their ability
                  to source a high calibre of candidates fitting our strict
                  criteria. The majority of the interviews organised with MSI
                  are successful and of a high standard, resulting in quick
                  deployment and excellent nurses joining our team.​I am
                  extremely pleased with the successful relationship our teams
                  have formed and look forward to continuing this project with
                  their support!
                </p>
                <h5>Portsmouth Hospitals NHS Trust</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
                <h2>"​Working with the team at MSI is a pleasure"</h2>
                <p>
                  ​Working with Georgina and the team at MSI is a pleasure.
                  There is always excellent communication and I know that the
                  candidates are being well looked after prior to their arrival
                  at the Trust.​I have full confidence in MSI and their ability
                  to source a high calibre of candidates fitting our strict
                  criteria. The majority of the interviews organised with MSI
                  are successful and of a high standard, resulting in quick
                  deployment and excellent nurses joining our team.​I am
                  extremely pleased with the successful relationship our teams
                  have formed and look forward to continuing this project with
                  their support!
                </p>
                <h5>Portsmouth Hospitals NHS Trust</h5>
              </div>
            </SwiperSlide>
          </Swiper> */}
        </div>
      </Wrapper>
    </>
  );
};

export default About;
