import React, { useEffect, useRef, useState } from "react";
import Logo from "../images/LOGO4.png";
import LogoTwo from "../images/logo2.png";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { babe, bg, crutch, nurse, psychology, stethoscope } from "../images";
import { HiMail, HiPhone } from "react-icons/hi";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

import styled from "styled-components";
import { Button } from "./style-components/Button";
import { Link } from "react-router-dom";

const Header = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 0.25s ease-in-out;
  &.scrolled {
    background: #909cb0;
    border: none;
    box-shadow: 2px 2px 2px 2px rgba(155, 155, 155, 0.3);
    font-size: 13px;
    height: 80px;
  }
`;

const Nav = styled.nav`
  width: 100vw;
  height: inherit;
  background-color: #f7eb91;
  background-color: #f5efc7;
  background-color: #f4f0d4;
  background: transparent;

  width: 90%;
  margin: 0 auto;
  /* padding: 25px 0; */

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.scrolled {
    /* padding: 10px; */
  }
`;

const LogoLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Figure = styled.figure`
  padding: 0;
  margin: 0;
`;
const Image = styled.img`
  width: 200px;
  margin-top: -10px;
  /* height: 60px; */
  &.scrolled {
    width: 150px;
    margin-top: 10px;
    /* height: 50px; */
  }
`;
const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  margin-left: 30px;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;
const ListItem = styled.li`
  list-style: none;
  padding: 0 25px;
  color: aliceblue;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.25s;

  & a {
    color: aliceblue;
    text-decoration: none;
  }
  & a:hover {
    color: #ffc13b;
  }
`;

const SocialMedia = styled.div`
  padding: 0px 50px;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  @media screen and (max-width: 992px) {
    display: ${(props) => props.breakpoint === "large" && "none"};
  }
`;

const SocialButton = styled.span`
  font-size: 16px;
  color: #fff;
  background-color: #ffae3b;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    background-color: #001b56;
  }

  &.scrolled {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);

  &:hover {
    animation: shake 0.85s ease-in-out both;
    transform: translate3d(0, 0, 0);
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const NavToggle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  z-index: 10;
  height: 35px;
  width: 35px;
  flex: 1;
  position: absolute;
  right: 20px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const ToggleBurger = styled.div`
  position: relative;
  height: 25px;
  /* width:36px; */
  right: 20px;

  & Span {
    height: 5px;
    width: 30px;
    position: absolute;
  }

  & Span:first-of-type {
    top: calc(50% - 8px);
    transition: width 0.25s ease-out 0.3s;
  }

  & Span:nth-of-type(2) {
    top: 50%;
    transition: width 0.25s ease-out 0.5s;
  }
  & Span:nth-of-type(3) {
    top: calc(50% + 8px);
    transition: width 0.25s ease-out 0.65s;
  }

  &.open-nav Span {
    width: 0;
  }
`;
const ToggleCross = styled.div`
  position: relative;
  right: 20px;
  bottom: 12px;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);

  & Span:first-of-type {
    position: absolute;
    left: 23px;
    top: 11px;
    height: 0;
    transition: height 0.25s ease-out 0.4s;

    width: 5px;
  }

  & Span:nth-of-type(2) {
    position: absolute;
    left: 14px;
    top: 20px;
    height: 5px;
    transition: width 0.25s ease-out 0.45s;
    width: 0;
  }

  &.open-nav Span:first-of-type {
    height: 27px;
  }

  &.open-nav Span:nth-of-type(2) {
    width: 27px;
  }
`;
const Span = styled.span`
  background: #ffc13b;
`;

const DropDownMenu = styled.div`
  position: absolute;
  top: 80px;
  right: 2rem;
  width: 300px;
  height: 0;
  background: #ffc13b;
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.9s;

  &.open-nav {
    height: 313px;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }

  @media screen and (max-width: 576px) {
    width: unset;
    left: 2rem;
  }
`;

const DropDownListItem = styled.li`
  list-style: none;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001b56;
  font-weight: 500;

  & a {
    color: #001b56;
    text-decoration: none;
    padding: 0.2rem;
  }
`;

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  let NavRef = useRef();

  const handleBackgroundChange = () => {
    if (window.scrollY >= 90) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (!NavRef.current.contains(e.target)) {
        setShowNav(false);
      }
    };
    document.addEventListener("mouseup", handleClose);

    return () => {
      document.removeEventListener("mouseup", handleClose);
    };
  });

  window.addEventListener("scroll", handleBackgroundChange);

  return (
    <Header className={scrolled && "scrolled"}>
      <Nav className={scrolled && "scrolled"}>
        <LogoLink>
          <Figure>
            <Link to="/">
              <Image src={Logo} alt="logo" className={scrolled && "scrolled"} />
            </Link>
          </Figure>

          <List>
            <ListItem>
              <Link to="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link to="/apply">Jobs</Link>
            </ListItem>
            <ListItem>
              <Link to="/contact-us">Contact</Link>
            </ListItem>
            <ListItem>
              <Link to="/about">About Us</Link>
            </ListItem>
          </List>
        </LogoLink>

        <SocialMedia breakpoint="large">
          <SocialButton>
            <FaFacebookF />
          </SocialButton>
          <SocialButton>
            <RiInstagramFill />
          </SocialButton>
          <SocialButton>
            <FaLinkedinIn />
          </SocialButton>
        </SocialMedia>

        <NavToggle ref={NavRef} onClick={() => setShowNav(!showNav)}>
          <ToggleBurger className={showNav && "open-nav"}>
            <Span></Span>
            <Span></Span>
            <Span></Span>
          </ToggleBurger>
          <ToggleCross className={showNav && "open-nav"}>
            <Span></Span>
            <Span></Span>
          </ToggleCross>
        </NavToggle>

        <DropDownMenu className={showNav && "open-nav"}>
          <DropDownListItem>
            <Link to="/">Home</Link>
          </DropDownListItem>
          <DropDownListItem>
            <Link to="/apply">Jobs</Link>
          </DropDownListItem>
          <DropDownListItem>
            <Link to="/contact-us">Contact</Link>
          </DropDownListItem>
          <DropDownListItem>
            <Link to="/about">About Us</Link>
          </DropDownListItem>

          <DropDownListItem>
            <SocialMedia>
              <SocialButton>
                <FaFacebookF />
              </SocialButton>
              <SocialButton>
                <RiInstagramFill />
              </SocialButton>
              <SocialButton>
                <FaLinkedinIn />
              </SocialButton>
            </SocialMedia>
          </DropDownListItem>
        </DropDownMenu>
      </Nav>
    </Header>
  );
};

export default Navbar;
