import React, { useEffect } from "react";

import ExtraSection from "../components/ExtraSection";
// import { babe, bg, crutch, nurse, psychology, stethoscope } from "../images";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import { Button, HeroButton } from "../components/style-components/Button";

// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

// import required modules
// import { Pagination, EffectCoverflow, Navigation } from "swiper";
import { Link } from "react-router-dom";

// styled component

const Main = styled.main``;
const Container = styled.div`
  width: 100vw;
`;
const HeroSec = styled.div`
  width: 100%;
  height: 850px;
  min-height: 40%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative;
`;

const HeroBackground = styled.div`
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("https://i.ibb.co/Jvr1V57/doctors.jpg");
  transform: translate3d(0px, 0px, 0px);

  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;

const ActionCall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;

  z-index: 2;
`;

const ActionCallInfo = styled.div``;

const LargeText = styled.p`
  font-size: 45px;
  font-weight: 900;
  text-transform: capitalize;
  color: aliceblue;
`;

const ActionSpan = styled.span`
  font-weight: 300;
`;

const SmallText = styled.p`
  font-size: 18px;
  text-transform: capitalize;
  color: aliceblue;
  margin-top: 20px;
`;

const ActionButton = styled.div`
  padding: 10px 0;

  @media screen and (max-width: 512px) {
    width: 90%;
  }
`;

const Content = styled.section`
  background-color: aliceblue;
  width: 100vw;
  height: auto;
  margin: 0;
`;

const About = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 800px) {
    width: 85%;
    margin: 0 auto;
    flex-direction: row;
    padding-bottom: 90px;
  }
`;

const AboutInfo = styled.div`
  color: #001b56;
  flex: 50%;

  @media screen and (min-width: 800px) {
    margin-right: 20px;
  }
`;
const Heading = styled.h2`
  font-size: 55px;
  font-weight: 500;
  @media screen and (min-width: 800px) {
    font-size: 75px;
  }
`;

const Description = styled.p`
  margin: 20px 0;

  letter-spacing: 1px;
  line-height: 30px;
  font-size: 14px;
  @media screen and (min-width: 800px) {
    font-size: 16px;
  }
`;

const ImageContainer = styled.figure`
  height: 350px;
  /* flex: 50%; */
  @media screen and (min-width: 800px) {
    height: 500px;
    flex: 50%;
  }
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 800px) {
    /* object-fit: none; */
  }
`;

// Slider Section
const SliderSec = styled.section`
  background: #f5f5f5;
  width: 100vw;
  height: 800px;
`;

const SliderInfo = styled.div`
  padding: 50px;
  text-align: center;
`;

const SliderTitle = styled.div`
  font-size: 33px;
  font-weight: 600;
  margin: 0;
`;

const SliderIntro = styled.div`
  font-size: 17px;
  font-weight: 300;
  margin-top: 20px;
  letter-spacing: 1.5px;
`;

const MainSlider = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const SliderCard = styled.div`
  width: 206px;
  min-height: 382px;
  text-align: center;
  background: white;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
  padding: 20px 20px 38px;
  display: -ms-grid;
  display: grid;
  grid-template-rows: 140px 65px 81px;

  position: relative;

  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
`;

const SliderIcon = styled.figure`
  margin: auto;
  height: 136px;
  width: 136px;
  background: url("../images/bg-hexagon.png") no-repeat center center;
  background-size: contain;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderImage = styled.img`
  max-width: 40%;
  max-height: 40%;
`;

const SliderHead = styled.h3`
  font-size: 25px;
  font-weight: 300;
`;

const SliderDescription = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #001e60;
  text-align: center;
  line-height: 22px;
  margin-bottom: 15px;
`;

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Golden Consult | Home";
  }, []);
  return (
    <Main>
      <Container>
        <HeroSec>
          <HeroBackground />

          <ActionCall>
            <ActionCallInfo>
              <LargeText>
                <ActionSpan>Experts in</ActionSpan> <br />
                international <br /> healthcare recruitment
              </LargeText>
              <SmallText>
                Access the global talent pool with Golden Consult
              </SmallText>
              <ActionButton>
                <Link to="/apply">
                  <HeroButton>Register with us</HeroButton>
                </Link>
                {/* <HeroButton marginTop="10px">Search for jobs</HeroButton> */}
              </ActionButton>
            </ActionCallInfo>
          </ActionCall>
        </HeroSec>
        <Content>
          <About>
            <AboutInfo>
              <Heading>About Us</Heading>
              <Description>
                Golden Consult is a UK based overseas recruitment agency
                consisting of specialist divisions including Nursing Care
                Solutions and Healthcare Solutions. We cover positions in both
                the public and private sector. We're committed to creating the
                most rewarding experiences for our customers, clients,
                colleagues, and our community.
                <br /> <br /> Our mission is to place the right candidate in the
                right job for their skills, experience, and requirements, and in
                the shortest time possible. We deliver this by ensuring the
                candidate is at the forefront of everything the team at GCS do.
              </Description>
              <Link to="/about">
                <Button>Find Out More</Button>
              </Link>
            </AboutInfo>
            <ImageContainer>
              <Image src="https://i.ibb.co/FHXTZcn/doctor-arms-folded.jpg" />
            </ImageContainer>
          </About>
        </Content>
        {/* <SliderSec>
          <SliderInfo>
            <SliderTitle>Choose your sector</SliderTitle>
            <SliderIntro>
              MSI International recruits overseas nurses, doctors and <br />
              AHPs for jobs in the UK.
            </SliderIntro>
          </SliderInfo>
          <MainSlider>
            <Swiper
              effect={"coverflow"}
              centeredSlides={true}
              loop={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              pagination={{ el: "swiper-pagination", clickable: true }}
              navigation={{
                nextEl: "slider-button-next",
                prevEl: "slider-button-prev ",
                clickable: true,
              }}
              className="swiper-container "
            >
              <SwiperSlide>
                <SliderCard>
                  <SliderIcon>
                    <SliderImage src={stethoscope} alt="stethoscope" />
                  </SliderIcon>
                  <SliderHead>Overseas Doctors</SliderHead>
                  <SliderDescription>
                    Relocating to the UK to work as a doctor with MSI
                    International offers an enormous amount of potential for
                    you, your career and your family.
                  </SliderDescription>
                </SliderCard>
              </SwiperSlide>

              <SwiperSlide>
                <SliderCard>
                  <SliderIcon>
                    <SliderImage src={nurse} alt="nurse" />
                  </SliderIcon>
                  <SliderHead>Overseas Nurses</SliderHead>
                  <SliderDescription>
                    MSI International have jobs for overseas nurses throughout
                    the UK.
                  </SliderDescription>
                </SliderCard>
              </SwiperSlide>

              <SwiperSlide>
                <SliderCard>
                  <SliderIcon>
                    <SliderImage src={babe} alt="baby icon" />
                  </SliderIcon>
                  <SliderHead>Overseas Midwives</SliderHead>
                  <SliderDescription>
                    MSI International cover jobs for overseas overseas midwives
                    in various parts of the UK.
                  </SliderDescription>
                </SliderCard>
              </SwiperSlide>

              <SwiperSlide>
                <SliderCard>
                  <SliderIcon>
                    <SliderImage src={crutch} alt="crutch" />
                  </SliderIcon>
                  <SliderHead>Allied Health Professionals</SliderHead>
                  <SliderDescription>
                    MSI International cover jobs for overseas allied health
                    professionals in various parts of the UK.
                  </SliderDescription>
                </SliderCard>
              </SwiperSlide>
            </Swiper>
            <div className="slider-controller">
              <div className="slider-button-prev slider-arrow">
                <FaArrowAltCircleLeft />
              </div>
              <div className="slider-button-next slider-arrow">
                <FaArrowAltCircleRight />
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </MainSlider>
        </SliderSec> */}

        {/* <ExtraSection>
          <ExtraSectionContainer>
            <ExtraSectionCard>
              <ExtraCardName>Send us your CV</ExtraCardName>
              <ExtraCardDescription>hjbkj</ExtraCardDescription>
              <Button>Send CV</Button>
            </ExtraSectionCard>
            <ExtraSectionCard>
              <ExtraCardName>Job alerts</ExtraCardName>
              <ExtraCardDescription>hjbkj</ExtraCardDescription>
              <Button>Set up alerts</Button>
            </ExtraSectionCard>
            <ExtraSectionCard>
              <ExtraCardName>Referrals</ExtraCardName>
              <ExtraCardDescription>hjbkj</ExtraCardDescription>
              <Button>Refer a friend</Button>
            </ExtraSectionCard>
          </ExtraSectionContainer>
        </ExtraSection>  
        <ExtraSection />*/}
      </Container>
    </Main>
  );
};

export default Home;

{
  /* <a href="https://ibb.co/hDyVtzZ"><img src="https://i.ibb.co/wMR4xmw/pexels-karolina-grabowska-4021766.jpg" alt="pexels-karolina-grabowska-4021766" border="0"></a>
<a href="https://ibb.co/mN44nTz"><img src="https://i.ibb.co/3Rccxdh/pexels-karolina-grabowska-4021769.jpg" alt="pexels-karolina-grabowska-4021769" border="0"></a> */
}

{
  /* <a href="https://ibb.co/MB1YDDP"><img src="https://i.ibb.co/KKsMjjV/doctor-arms-folded.jpg" alt="doctor-arms-folded" border="0"></a>
<a href="https://ibb.co/sjZD390"><img src="https://i.ibb.co/PxdbCG7/doctor-medicine.jpg" alt="doctor-medicine" border="0"></a>
<a href="https://ibb.co/phQFVNn"><img src="https://i.ibb.co/FHXTZcn/doctor-arms-folded.jpg" alt="doctor-arms-folded" border="0"></a>
*/
}

// const responsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 4,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
//   const {
//     carouselState: { currentSlide },
//   } = rest;
//   return (
//     <div
//       className="carousel-button-group mb-4  gap-4 flex justify-end
//         items-center w-full"
//     >
//       <button className="carousel-left" onClick={() => previous()}>
//         <FiChevronLeft />
//       </button>
//       <button onClick={() => next()} className="carousel-right">
//         <BiChevronRight />
//       </button>
//     </div>
//   );
// };

{
  /* <Carousel
                  // responsive={responsive}
                  swipeable={true}
                  draggable={false}
                  showDots={true}
                  // ssr={true}
                  infinite={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  // arrows={false}
                  // renderButtonGroupOutside={true}
                  // customButtonGroup={<ButtonGroup />}
                >
                  <div className="slider-card">
                    <figure className="slider-icon">
                      <img src={psychology} alt="psychology" />
                    </figure>
                    <h3 className="slider-head">Overseas Psychologists</h3>
                    <p className="slider-description">
                      MSI International recruit qualified psychologists from
                      around the world to come and work in the UK.
                    </p>
                  </div>
                  <div className="slider-card">
                    <figure className="slider-icon">
                      <img src={crutch} alt="crutch" />
                    </figure>
                    <h3 className="slider-head">Allied Health Professionals</h3>
                    <p className="slider-description">
                      MSI International cover jobs for overseas allied health
                      professionals in various parts of the UK.
                    </p>
                  </div>
                  <div className="slider-card">
                    <figure className="slider-icon">
                      <img src={babe} alt="baby icon" />
                    </figure>
                    <h3 className="slider-head">Overseas Midwives</h3>
                    <p className="slider-description">
                      MSI International cover jobs for overseas overseas
                      midwives in various parts of the UK.
                    </p>
                  </div>
                  <div className="slider-card">
                    <figure className="slider-icon">
                      <img src={stethoscope} alt="stethoscope" />
                    </figure>
                    <h3 className="slider-head">Overseas Doctors</h3>
                    <p className="slider-description">
                      Relocating to the UK to work as a doctor with MSI
                      International offers an enormous amount of potential for
                      you, your career and your family.
                    </p>
                  </div>
                  <div className="slider-card">
                    <figure className="slider-icon">
                      <img src={nurse} alt="crutch" />
                    </figure>
                    <h3 className="slider-head">Overseas Nurses</h3>
                    <p className="slider-description">
                      MSI International have jobs for overseas nurses throughout
                      the UK.
                    </p>
                  </div>
                </Carousel> */
}
