import React, { useEffect } from "react";
import Wrapper, { SocialButton } from "../styling/contact.style";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { MdEmail, MdLocalPhone, MdLocationPin } from "react-icons/md";
import contactImaage from "../images/pexels-alex-andrews-821754.jpg";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Golden Consult | Contact Us";
  }, []);
  return (
    <>
      <Wrapper>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${contactImaage})`,
            backgroundPosition: "center",
          }}
        >
          <div className="hero-overlay"></div>
          <h2 className="hero-text">Contact Us</h2>
        </div>
        <div className="container">
          {/* <h2 className="heading">Contact Us</h2> */}
          <div className="contact">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.510710689294!2d0.1019836!3d51.50384580000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8af4e2ff7275b%3A0xa60477be1784641f!2s68%20Waterside%20Cl%2C%20London%20SE28%200GS%2C%20UK!5e0!3m2!1sen!2sgh!4v1679513677129!5m2!1sen!2sgh"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="contact-info">
              <div className="getInTouch">
                <h5 className="heading">Get in touch</h5>
                <ul>
                  <li>
                    <MdLocationPin />
                    {/* MSI International Head Office <br /> */}
                    68 Waterside Close, <br />
                    Southeast London,
                    <br />
                    SE28 0GS
                  </li>
                  <li>
                    <MdEmail />
                    <a href="mailto:info@goldenconsult.co.uk">
                      info@goldenconsult.co.uk
                    </a>
                  </li>
                  <li>
                    <MdLocalPhone /> +44 7577062096
                  </li>
                  <li>+44 7432620694</li>
                </ul>
              </div>
              <div className="followUs">
                <h5 className="heading">Follow us online</h5>
                <div className="social-icons">
                  <SocialButton>
                    <FaFacebookF />
                  </SocialButton>
                  <SocialButton>
                    <FaLinkedinIn />
                  </SocialButton>
                  <SocialButton>
                    <FaInstagram />
                  </SocialButton>
                </div>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </Wrapper>
    </>
  );
};

export default ContactUs;
