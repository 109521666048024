import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { AiOutlineEye } from "react-icons/ai";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import AdminWrapper from "../styling/Admin.style";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebaseConfig/config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminContacts = () => {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getApplications = async () => {
    let arr = [];
    const q = query(collection(db, "contact-form"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
      setData(arr);
    });
  };

  const columns = [
    { id: "#", label: "#" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "Email", label: "Email" },
    { id: "country", label: "Country" },
    { id: "message", label: "Message" },
  ];

  const rows = data?.map((item, index) => {
    return {
      "#": index + 1,
      firstName: item.firstName,
      lastName: item.lastName,
      Email: item.email,
      Phone: item.phone,
      country: item.country,
      message: item.message,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  useEffect(() => {
    getApplications();
  }, []);
  return (
    <AdminWrapper>
      <div className="main">
        <div className="container">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer style={{ minHeight: 620 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="relative z-0">
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        <p className="font-bold">{column.label}</p>
                      </TableCell>
                    ))}
                    {/* <TableCell>
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        Action
                      </p>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return <TableCell key={index}>{value}</TableCell>;
                          })}
                          {/* <TableCell
                            onClick={handleClickOpen}
                            className="cursor-pointer"
                            style={{
                              fontWeight: 700,
                              fontSize: 22,
                              textAlign: "center",
                            }}
                          >
                            <span>
                              <AiOutlineEye />
                            </span>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
    </AdminWrapper>
  );
};

export default AdminContacts;
