import styled from "styled-components";

const about = styled.section`
  .about-sec {
    display: flex;
    gap: 2rem;
    margin: 5rem;
  }
  .about-sec img {
    width: 25rem;
    margin: auto;
  }
  .heading {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2.5rem;
  }
  .heading-text {
    margin: 20px 0;

    letter-spacing: 1px;
    line-height: 30px;
    font-size: 14px;
    color: var(--primary-700);
  }
  .archievments {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    min-height: 350px;
    padding: 2rem 1rem;
  }
  .archievment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    text-align: center;
  }
  .archievment-header p {
    background-image: url("https://www.msiinternational.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBelFpQlE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--3d4e36ac48e7d3da22b1b7c564ae930e14d378f5/hexagon-blue-light.png");
    background-repeat: no-repeat;
    width: 200px;
    height: 220px;
    font-size: 3rem;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .archievment-body {
    color: var(--primary-700);
  }
  .banner {
    background-image: url("https://www.msiinternational.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMklpQlE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--b46b9b94938dd889d43dac1f25911da189906959/background-blue-1.png");
    min-height: 650px;
    margin-bottom: 100px;
    /* background-repeat: no-repeat; */
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    color: white;
    padding: 5rem;
  }
  .bannerSec {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .banner-text {
    font-size: 2.5rem;
  }
  .banner strong {
    font-weight: 900;
  }
  .mySwiper {
    width: 60%;
  }
  .testimonials {
    min-height: 490px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
  }
  .testimonials h2 {
    color: var(--primary-400);
  }
  .testimonials p {
    color: grey;
  }
  .testimonials h5 {
    color: var(--primary-400);
  }

  @media (max-width: 1200px) {
    .about-sec {
      display: flex;
      flex-direction: column-reverse;
      margin: 2rem;
      margin-top: 5rem;
    }
    .about-sec img {
      width: 25rem;
    }
  }
  .bannerSec {
    width: 60%;
  }
  .testimonials {
    min-height: 450px;
  }

  @media (max-width: 900px) {
    .banner {
      padding: 1rem;
      flex-direction: column;
    }
    .bannerSec {
      width: 100%;
    }
    .mySwiper {
      margin-top: 1rem;
      width: 70%;
    }
    .banner-text {
      font-size: 2rem;
    }
    .testimonials p {
      color: grey;
    }
  }
  @media (max-width: 567px) {
    .mySwiper {
      width: 90%;
    }
  }
  @media (max-width: 460px) {
    .about-sec img {
      width: 17rem;
    }
  }
`;

export default about;
